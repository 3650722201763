import { Row,Col,Container } from "react-bootstrap"

const Footer=()=>{
    return(
       
        <div className="border-top pb-2">
        <Container className="container p-2">
            <Row className="row g-4">
                <Col md={6} xs={12}>
                    <h6 className="border-bottom pb-1 mb-4">SAN ANTONIO DE ARECO</h6>
                    <Row className="row g-4 text-muted super-small">
                        <Col md={4} xs={12}>
                            <p className="fw-bold mb-2">Rectorado</p>
                            <p className="text-align-justify mb-0">
                                Zapiola N° 362 (CP 2760)<br/>
                                Teléfono: 02326 453079<br/>
                                <b>San Antonio de Areco</b>, Bs. As
                            </p>
                        </Col>
                        <Col md={4} xs={12}>
                            <p className="fw-bold mb-2">Sede académica Güiraldes</p>
                            <p className="text-align-justify mb-0">
                                Av. Güiraldes N° 689 (CP 2760)<br/>
                                Teléfono: 02326 453079<br/>
                                <b>San Antonio de Areco</b>, Bs. As
                            </p>
                        </Col>
                        <Col md={4} xs={12}>
                            <p className="fw-bold mb-2">Sede académica Rivadavia</p>
                            <p className="text-align-justify mb-0">
                                Rivadavia y Quetgles (CP 2760)<br/>
                                Teléfono: 02326 455025<br/>
                                <b>San Antonio de Areco</b>, Bs. As
                            </p>
                        </Col>
                    </Row>
                </Col>
                <Col md={6} xs={12}>
                    <h6 className="border-bottom pb-1 mb-4">BARADERO</h6>
                    <Row className="row g-4 text-muted super-small">
                        <Col md={4} xs={12}>
                            <p className="fw-bold mb-2">Sede administrativa</p>
                            <p className="text-align-justify mb-0">
                                Bolaños 1637 (CP 2942)<br/>
                                Teléfono: 03329 485780<br/>
                                <b>Baradero</b>, Buenos Aires
                            </p>
                        </Col>
                        <Col md={4} xs={12}>
                            <p className="fw-bold mb-2">Sede académica</p>
                            <p className="text-align-justify mb-0">
                                Gallo 220 (CP 2942)<br/>
                                Teléfono: 03329 488678<br/>
                                <b>Baradero</b>, Buenos Aires
                            </p>
                        </Col>
                        <Col md={4} xs={12}>
                            <p className="fw-bold mb-2">CUB</p>
                            <p className="text-align-justify mb-0">
                                Thames 1594 (CP 2942)<br/>
                                Teléfono: 03329 488678<br/>
                                <b>Baradero</b>, Buenos Aires
                            </p>
                        </Col>
                    </Row>
                </Col>
                </Row>
           
        </Container>
    </div>
       
    )
}
export default Footer;