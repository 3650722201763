import { Col, Row ,Nav, Navbar, Container} from "react-bootstrap"
import logo from "../assest/unsada_logo_400.jpg"
import { FaFacebookF,FaInstagram,FaYoutube,FaEnvelope } from 'react-icons/fa';


 const Header=()=>{
    return(
       
       
        <Row className="row g-3 align-items-end">
            <Col className="col-md text-center text-md-start logo">
            <a href="https://www.unsada.edu.ar/"  ><img  alt="Logo"  src={logo}/></a>
            
            </Col>
            <Col className="col-md icons">
                <ul className="d-flex mb-0 mb-md-3 justify-content-center justify-content-md-end list-unstyled">
                    <li><a className="p-3 px-3" style={{color:"#000"}} href="https://www.facebook.com/unsadaoficial/" target="_blank"> <FaFacebookF size={24}/></a></li>
                    <li><a className="p-3 px-3" style={{color:"#000"}} href="https://www.instagram.com/unsadaoficial/" target="_blank"><FaInstagram size={24}/></a></li>
                    <li><a className="p-3 px-3" style={{color:"#000"}} href="https://www.youtube.com/unsadaeduar" target="_blank"><FaYoutube size={24}/></a></li>
                    <li><a className="p-3 px-3" style={{color:"#000"}} href="mailto:unsada@unsada.edu.ar" ><FaEnvelope size={24}/></a></li>
                </ul>
            </Col>
        </Row>
      
           
    )
}
export default Header;