import React from 'react';
import logo from '../assest/icono-02.svg'; // Asegúrate de importar correctamente tu archivo SVG

const IconDiploma = () => {
  return (
    <div>
      <img src={logo} alt="diploma" height="40px" width="40px" />
      {/* Otro contenido de tu aplicación */}
    </div>
  );
};

export default IconDiploma;