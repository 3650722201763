

import { Table,Button } from "react-bootstrap";
import { FaFilePdf } from "react-icons/fa";
import IconDiploma from "./IconDiploma";
import IconAnalitico from "./IconAnalitico"

const Tabla=({isVisible,graduados})=>{
  

    
    return(
      <div hidden={isVisible}>
        <Table striped bordered hover responsive  style={{ marginTop: "2em" }}>
            <thead>
              <tr>
                <th>NÚMERO</th>
                <th>APELLIDO Y NOMBRE </th>
                <th>DOCUMENTO</th>
                <th>TÍTULO</th>
                <th>RESOLUCIÓN MINISTERIAL</th>
                <th>ANALÍTICO</th>
                <th>DIPLOMA</th>
              </tr>
            </thead>
            <tbody>
              {graduados.map((data, i) => {
                return (
                  <tr key={i}>
                    <td>{data.nroGraduado}</td>
                    <td>{data.apellidoNombre}</td>
                    <td>{data.dni}</td>
                    <td>{data.titulo}</td>
                    <td>{data.resolucionMinisterial}</td>
                    <td >
                      <div className='logo-pdf'>
                        <Button variant="trasparent" href={`https://diplomas.unsada.edu.ar/pdf/analitico/A${data.nroGraduado}.pdf`} target='_blank'>
                          {/*<FaFilePdf color='#f80f00' size={32}></FaFilePdf>*/}
                          <IconAnalitico/>
                        </Button>
                      </div>
                    </td>
                    <td >
                      <div className='logo-pdf'>
                        <Button variant="trasparent" href={`https://diplomas.unsada.edu.ar/pdf/diploma/D${data.nroGraduado}.pdf`} target='_blank'>
                          {/*<FaFilePdf color='#f80f00' size={32}></FaFilePdf>*/}
                          <IconDiploma />
                          
                        </Button>
                      </div>
                    </td>
                  </tr>
                )
              })}

            </tbody>
          </Table>
          </div>
    )
}
export default Tabla;