import React from 'react';
import logo from '../assest/icono-01.svg'; // Asegúrate de importar correctamente tu archivo SVG

const IconAnalitico = () => {
  return (
    <div>
      <img src={logo} alt="analitico" height="40px" width="40px"/>
      {/* Otro contenido de tu aplicación */}
    </div>
  );
};

export default IconAnalitico;