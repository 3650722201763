import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Header from './components/Header';
import { Card, Container, Form, Row, Col, Button, Table } from 'react-bootstrap';
import { useState } from 'react';
import axios from 'axios';
import { FaFilePdf } from "react-icons/fa"
import Footer from './components/Footer';
import Tabla from './components/Tabla';
import PDFViewer from './components/PDFViewer';
import Banner from './components/Banner';

function App() {
  const [dni, setDni] = useState()
  const [graduados, setGraduados] = useState([]);
  const [isVisible, setIsVisible] = useState(true)
  const [isNotData,setIsNotData]= useState(false)

  const handleChange = (e) => {
    setIsVisible(true)
    setIsNotData(false)
    setDni(e.target.value)
  }
  const handleSubmit = (e) => {
    setIsNotData(false)
    e.preventDefault();
    axios.get("https://api.diplomas.unsada.edu.ar/api/graduados/getTitulosByDni/" + dni)
      .then((resp) => {
        console.log(resp)
        setGraduados(resp.data.data)
        setIsVisible(false)
        if(resp.data.data.length===0){
          setIsNotData(true);
        }
      })
      .catch((error) => {
        console.log(error)
        setIsVisible(true)
      })
    console.log(dni)
  }







  return (
    <Container >
      <Header />
      <Banner/>
      <Card style={{ marginTop: "0em", marginBottom: '2em' }}>
        <Card.Header>
          <Card.Title ><h2>Registros de diplomas UNSAdA </h2></Card.Title>
        </Card.Header>
        <Card.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formDni">
              <Form.Label as="div" >
                <h3>
                  Ingrese un número de documento para consultar .
                </h3>
              </Form.Label>
              <Row className='form-dni'>
                <Col className='input-col'>
                  <Form.Control type='number' placeholder='Numero de documento' required onChange={handleChange} />
                </Col>
                <Col >
                  <Button variant="primary" size='lg' type='submit' >Consultar</Button>{' '}
                </Col>
              </Row>


            </Form.Group>

          </Form>
            {!isNotData &&<Tabla isVisible={isVisible} graduados={graduados}/>}
           {isNotData && <Card.Subtitle className='text-center'>No se encontraron resultados para el documento: {dni} </Card.Subtitle> }
        </Card.Body>

      </Card>
      {/*<PDFViewer pdfUrl="https://egresados.unsada.edu.ar/pdf/analitico/AP0015.pdf"/>*/}
      <Footer />
    </Container>


  );
}

export default App;
