import React from 'react';

import { Container } from 'react-bootstrap'; 
import banner from '../assest/banner.jpg';

const Banner = () => {
  return (
    <Container className="d-flex justify-content-center align-items-center " style={{padding:"0"}}>
      <img src={banner} alt="Imagen" className="img-fluid" />
    </Container>
  );
};

export default Banner;