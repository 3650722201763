import React, {useState} from 'react';
import { Document, Page,pdfjs } from 'react-pdf';

function PDFViewerComponent({ pdfUrl }) {
  pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.js',
    import.meta.url,
  ).toString();

  const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages , numPages: number }) {
    setNumPages(numPages);
  }
  return (
    <div>
      <Document file={pdfUrl}  onLoadSuccess={onDocumentLoadSuccess}>
        <Page pageNumber={1} />
      </Document>
      
    </div>
  );
}

export default PDFViewerComponent;


